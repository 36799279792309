import Vue from "vue";
import * as MutationsType from "./mutationsType";
import { roles } from "@/helpers/get_constants";

/* eslint-disable no-param-reassign */
export default {
  [MutationsType.SET_AGENT](state, payload) {
    state.agent = payload;
  },
  [MutationsType.SET_AGENTS](state, payload) {
    state.agents = payload;
  },
  [MutationsType.SET_ALL_AGENTS](state, payload) {
    state.allAgents = payload;
  },
  [MutationsType.SET_ALL_AGENTS_MAP](state, payload) {
    state.allAgentsMap = payload;
  },
  [MutationsType.UPDATE_AGENT](state, payload) {
    let ind = state.allAgents.findIndex((agent) => agent.uid === payload.uid);
    if (ind >= 0) Vue.set(state.allAgents, ind, { ...state.allAgents[ind], ...payload });
  },
  [MutationsType.SET_SELLER_AGENTS](state, payload) {
    state.sellerAgents = payload;
  },
  [MutationsType.SET_AGENT_AUTHENTICATED](state, payload) {
    state.agentAuthenticated = payload;
  },
  [MutationsType.SET_AGENT_SELECTED](state, payload) {
    state.agentSelected = payload;
  },
  [MutationsType.SHOW_AGENT_DETAIL](state, payload) {
    state.agentId = payload;
    state.showAgentDetailDrawer = !state.showAgentDetailDrawer;
  },
  [MutationsType.SET_MENU_ITEMS](state, payload) {
    let items = [];

    if (payload.role === roles.ENTREPRENEUR) items.push(state.menuOrder, ...state.menuBase, state.menuStatistics);

    if ([roles.SUPER_ADMIN, roles.ADMIN].includes(payload.role))
      items.push(state.menuOrder, ...state.menuBase, state.menuAgents, state.menuStatistics);

    if ([roles.ADVISOR, roles.SUPERVISOR].includes(payload.role)) items = state.menuBase;

    state.menu = items;
  },
};
