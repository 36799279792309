<template>
  <v-app id="tickets-dashboard">
    <div v-if="getAgent && getAgents && groups && getTickets">
      <v-col cols="12" class="pa-0">
        <TicketHeader
          :query.sync="query"
          :show-filters.sync="showFilters"
          :custom-filters.sync="customFilters"
          @loadTickets="loadTickets"
          @applyFilter="applyFilter"
        />
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-card class="mb-5 pb-5 rounded-lg">
          <v-col cols="12">
            <v-progress-linear
              :active="loadingTickets"
              :indeterminate="loadingTickets"
              absolute
              top
              color="secondary accent-4"
            ></v-progress-linear>
            <div class="d-flex flex-nowrap pa-0" style="overflow-x: scroll;">
              <div v-drag-and-drop:options="options" class="col-12 pa-0">
                <ul class="drag-list col-12 pa-0 pb-4 flex-nowrap fill-height">
                  <li
                    v-for="group in groups"
                    :key="group.id"
                    class="drag-column col-4 fill-height"
                    :style="{ background: $vuetify.theme.themes[theme].background }"
                  >
                    <span class="drag-column-header">
                      <h2 class="font-weight-black">{{ group.name }}</h2>
                      <v-chip small color="secondary" class="black--text">
                        {{ group.nb_hits + group.items.length }}
                      </v-chip>
                    </span>
                    <vue-draggable-group v-model="group.items" :groups="groups" :data-id="group.id">
                      <ul
                        :id="group.id"
                        class="drag-inner-list col-12 px-0 pt-0 fill-height"
                        :data-id="group.id"
                        @added="added"
                      >
                        <li
                          v-for="item in group.items"
                          :id="item.ticket_id"
                          :key="item.id"
                          class="drag-item"
                          :data-id="item.id"
                        >
                          <v-card
                            class="mx-auto pa-0 pb-2 mb-4 elevation-0 rounded-lg"
                            max-width="400"
                            @click="showTicketDetail({ ticketId: item.ticket_id })"
                          >
                            <div class="d-flex pa-4 pb-0">
                              <v-icon class="far fa-clock mr-1" size="12"></v-icon>
                              <span class="caption font-weight-light w100">{{
                                $formatTimestamp(item.created_at)
                              }}</span>
                            </div>
                            <v-card-title class="py-0 mb-4">
                              <v-row class="w-full">
                                <v-col cols="10" class="pb-0 d-flex align-center">
                                  <h5 class="subtitle-1 font-weight-bold w100">
                                    {{ item.full_name }}
                                  </h5>
                                </v-col>
                                <v-col cols="2" class="text-right pb-0">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        text
                                        icon
                                        :to="{ name: 'ticket_detail', params: { id: item.ticket_id } }"
                                        target="_blank"
                                        v-on="on"
                                        @click.stop
                                      >
                                        <v-icon size="13">fas fa-external-link-alt</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Ver oportunidad</span>
                                  </v-tooltip>
                                </v-col>
                              </v-row>
                            </v-card-title>

                            <v-card-text>
                              <v-row class="px-3 pb-2">
                                <div v-for="l in getActiveTags" :key="l.name">
                                  <v-chip
                                    v-if="item.tags && item.tags.includes(l.id)"
                                    class="px-3 mr-1"
                                    style="height: 7px;"
                                    :color="l.color"
                                  ></v-chip>
                                </div>
                              </v-row>
                              <!-- <span class="font-weight-bold">Ultima actividad: </span> -->
                              <span v-html="item.last_comment"></span><br />
                              <span class="caption font-weight-light">{{ $formatTimestamp(item.last_activity) }}</span>
                            </v-card-text>
                            <v-divider class="mt-2 mx-2" />
                            <v-card-actions v-if="item.agent_id && getAllAgentsMap[item.agent_id]" class="pb-1 pr-2">
                              <v-spacer></v-spacer>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-avatar size="25" color="accent" class="mr-2" v-on="on">
                                    <img
                                      v-if="getAllAgentsMap[item.agent_id].photo"
                                      :src="getAllAgentsMap[item.agent_id].photo"
                                      alt="Avatar"
                                    />
                                    <span v-else class="white--text">
                                      {{ getAllAgentsMap[item.agent_id].full_name.substring(0, 1) }}
                                    </span>
                                  </v-avatar>
                                </template>
                                <span>Asignado a {{ getAllAgentsMap[item.agent_id].full_name }}</span>
                              </v-tooltip>
                            </v-card-actions>
                          </v-card>
                        </li>
                        <div class="py-3 text-center">
                          <v-btn
                            v-if="group.nb_hits > 0"
                            class="mb-5"
                            float
                            text
                            color="primary"
                            @click="loadMoreTickets(group.id)"
                            >Ver más resultados</v-btn
                          >
                        </div>
                      </ul>
                    </vue-draggable-group>
                  </li>
                </ul>
              </div>
              <v-snackbar v-model="snackbar" :bottom="true" :multi-line="true" :right="true" :timeout="10000" dark>
                <v-btn icon color="white" :loading="true"><v-icon size="16">fa-plus</v-icon></v-btn>
                <span class="text--white">{{ snackbar_text }}</span>
              </v-snackbar>
            </div>
          </v-col>
        </v-card>
      </v-col>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";

import { ticketsIndex } from "@/config/algolia";
import { formatFilters } from "@/helpers/format_algoliaparams";

import TicketHeader from "@/views/tickets/inc/Header.vue";

export default {
  name: "TicketsDashboard",
  components: {
    TicketHeader,
  },
  data() {
    return {
      query: "",
      customFilters: config.ticketFilters,
      filters_selected: {},
      count: 0,
      groups: [],
      snackbar: false,
      snackbar_text: "",
      loadingTickets: false,
      loadingExport: false,
      showFilters: true,
      options: {
        dropzoneSelector: ".drag-inner-list",
        draggableSelector: ".drag-item",
      },
    };
  },
  computed: {
    ...mapGetters(["getAgent", "getAgents", "getAllAgentsMap", "getTickets", "getActiveTicketStates", "getActiveTags"]),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {
    getAgents(value) {
      if (value) {
        if (this.getAgent.role === this.$roles.ADVISOR)
          this.filters_selected["agent_id"] = { value: this.getAgent.uid };
        if ([this.$roles.SUPERVISOR, this.$roles.ENTREPRENEUR].includes(this.getAgent.role))
          this.filters_selected["team_id"] = { value: this.getAgent.team_id };

        this.filters = formatFilters(this.filters_selected);
        this.loadUI();
      }
    },
    getTickets(value) {
      if (value) {
        for (let i = 0; i < this.getActiveTicketStates.length; i++) {
          this.groups[i].items = [];
        }
        for (let i = 0; i < value.length; i++) {
          let ticket = value[i];
          let ind = this.getActiveTicketStates.findIndex((state) => {
            return state.id === ticket.state;
          });
          this.groups[ind].items.push({
            ...ticket,
            ticket_id: ticket.id || ticket.ticket_id,
            id: i,
            groupId: this.getActiveTicketStates[ind].name,
          });
        }

        this.loadingTickets = false;
      }
    },
  },
  async created() {
    await this.getAllTicketStates();
    await this.getAllAgents();
  },
  methods: {
    ...mapActions([
      "getAllAgents",
      "getAllTicketStates",
      "downloadTickets",
      "setTicketState",
      "setGroupTickets",
      "showTicketDetail",
    ]),
    async loadUI() {
      this.groups = [];

      for (let i = 0; i < this.getActiveTicketStates.length; i++) {
        let state = this.getActiveTicketStates[i];
        this.groups.push({ id: state.id, name: state.name, items: [], page: 0, nb_pages: 1, nb_hits: 0 });
      }

      let data = await ticketsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: this.filters,
      });

      for (let filter in data.facets) {
        let items = [{ id: "", name: "Todos", full_name: "Todos" }];

        if (filter === "state") items.push(...this.getActiveTicketStates);
        else if (filter === "agent_id")
          items.push({ id: "none", full_name: "Sin asignar" }, ...this.loadFilterAgents(data.facets[filter]));
        else
          for (let field in data.facets[filter]) {
            items.push({ id: field, name: field });
          }

        this.customFilters[filter].items = items;
        this.customFilters[filter].value = this.filters_selected[filter] ? this.filters_selected[filter].value : "";
      }

      this.loadTickets();
    },
    loadFilterAgents(facet) {
      let agents = [];
      for (let f in facet) {
        if (f !== "none") agents.push(this.getAllAgentsMap[f]);
      }
      return agents;
    },
    async loadTickets(changeFilters = false) {
      this.loadingTickets = true;

      let tickets = [];

      for (let i = 0; i < this.getActiveTicketStates.length; i++) {
        let state = this.getActiveTicketStates[i];

        let items = changeFilters ? [] : this.groups[i].items;
        let page = changeFilters ? 0 : this.groups[i].page;
        let nb_pages = changeFilters ? 0 : this.groups[i].nb_pages;
        let nb_hits = changeFilters ? 0 : this.groups[i].nb_hits;

        if (!this.customFilters["state"].value || this.customFilters["state"].value === state.id) {
          let data = await ticketsIndex.search(this.query, {
            facets: Object.keys(this.customFilters),
            filters: `state:${state.id} ${this.filters ? "AND" : ""} ${this.filters}`,
            hitsPerPage: 10,
            page: 0,
          });

          items = data.hits;
          nb_pages = data.nbPages;
          nb_hits = data.nbHits - items.length;
        }

        tickets.push(...items);

        this.groups[i].page = page;
        this.groups[i].nb_pages = nb_pages;
        this.groups[i].nb_hits = nb_hits;
      }

      this.setGroupTickets({ tickets_selected: tickets, refresh: true });
    },
    async loadMoreTickets(state) {
      let ind_state = this.getActiveTicketStates.findIndex((s) => s.id == state);
      let data = await ticketsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: `state:${state} ${this.filters ? "AND" : ""} ${this.filters}`,
        hitsPerPage: 10 * (++this.groups[ind_state].page + 1),
        page: 0,
      });

      this.setGroupTickets({
        tickets_selected: [...this.getTickets, ...this.deleteDuplicateTickets(ind_state, data.hits)],
        refresh: true,
      });

      this.groups[ind_state].nb_pages = data.nb_pages;
    },
    deleteDuplicateTickets(ind_state, hits) {
      let tickets = [];
      let hit;

      for (let i = 0; i < hits.length; i++) {
        hit = hits[i];

        if (!this.groups[ind_state].items.find((item) => item.ticket_id === hit.id))
          tickets.push({ ticket_id: hit.id, ...hit });
      }

      this.groups[ind_state].nb_hits -= tickets.length;
      return tickets;
    },
    applyFilter(filter_id, filter_value) {
      this.customFilters[filter_id].value = filter_value;
      this.filters = formatFilters({ ...this.customFilters, ...this.filters_selected });

      this.loadTickets(true);
    },
    async added(e) {
      try {
        this.snackbar = true;
        this.snackbar_text = `Cambiando estado a ${e.detail.droptarget.id}`;

        await this.setTicketState({
          ticket_id: e.detail.items[0].id,
          state: e.detail.droptarget.id,
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.snackbar = false;
      }
    },
  },
};
</script>
<style>
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li.drag-column ul {
  overflow-x: none !important;
}

.drag-container {
  max-width: 1000px;
  margin: 20px auto;
}

.drag-list {
  display: flex;
  align-items: flex-start;
}

.drag-column {
  margin: 0 5px;
  background: #eee;
  border-radius: 8px;
  overflow: hidden;
  height: 85vh;
}

.drag-column h2 {
  font-size: 0.8rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
}

.drag-column-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  user-select: none;
}

.drag-inner-list {
  overflow-x: scroll;
  padding-left: 0 !important;
}

.drag-item {
  margin: 8px;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
}

.drag-item[aria-grabbed="true"] {
  background: #fff;
  opacity: 1 !important;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 7rem;
  border-radius: 8px;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
  margin-left: 0.6rem;
  margin-right: 0.6rem;
}

@media (max-width: 690px) {
  .drag-column {
    margin-bottom: 30px;
  }
  .drag-list {
    display: block;
  }
}
</style>
