var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-app", { staticClass: "pt-16 pt-md-0 pt-lg-0 mb-10 pb-10" }, [
    _vm.getAgent
      ? _c(
          "div",
          [
            _c(
              "h2",
              {
                staticClass:
                  "text-h4 primary--text py-8 font-weight-black hidden-sm-and-down"
              },
              [_vm._v("Configuraciones")]
            ),
            _vm._v(" "),
            _c(
              "v-card",
              { staticClass: "rounded-lg" },
              [_c("Notifications"), _vm._v(" "), _c("DarkMode")],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { staticClass: "py-5 mt-5 px-0", attrs: { cols: "12" } },
              [
                _c("h4", [_vm._v("Configuraciones avanzadas")]),
                _vm._v(" "),
                _c(
                  "v-card",
                  { staticClass: "rounded-lg mt-4" },
                  [
                    [_vm.$roles.SUPER_ADMIN, _vm.$roles.ENTREPRENEUR].includes(
                      _vm.getAgent.role
                    )
                      ? _c("Tickets")
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.getAgent.role === _vm.$roles.SUPER_ADMIN
                      ? _c("TeamsAgents")
                      : _vm._e(),
                    _vm._v(" "),
                    _c("Integrations")
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              { staticClass: "pt-5 mt-5 px-0", attrs: { cols: "12" } },
              [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "accent", text: "" },
                    on: { click: _vm.signout }
                  },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mr-2", attrs: { small: "" } },
                      [_vm._v("fas fa-sign-out-alt")]
                    ),
                    _vm._v(" Cerrar sesión\n      ")
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }