import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/messaging";
import config from "Config/config";

// Initialize Firebase
firebase.initializeApp(config.app);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(config.fcm_key);
}
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

const auth = firebase.auth();
const db = firebase.firestore();
const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
const contactsCollection = db.collection("leads");
const agentsCollection = db.collection("agents");
const contactsTimelinesCollection = db.collection("timelines");
const eventsCollection = db.collection("events");
const ordersCollection = db.collection("orders");
const ordersStatesCollection = db.collection("orders_states");
const ordersTimelinesCollection = db.collection("orders_timelines");
const ticketsCollection = db.collection("tickets");
const ticketsTimelinesCollection = db.collection("tickets_timelines");
const ticketsStatesCollection = db.collection("tickets_states");
const ticketsDownloadsCollection = db.collection("tickets_downloads");
const productsCollection = db.collection("products");
const tagsCollection = db.collection("tags");
const devicesCollection = db.collection("devices");
const projectsCollection = db.collection("projects");
const teamsCollection = db.collection("teams");
const webhooksCollection = db.collection("webhooks");
const integrationsCollection = db.collection("integrations");
const campaignsBudgetsCollection = db.collection("campaigns_budgets");

export {
  db,
  auth,
  messaging,
  contactsCollection,
  agentsCollection,
  contactsTimelinesCollection,
  ticketsTimelinesCollection,
  ordersTimelinesCollection,
  eventsCollection,
  ordersCollection,
  ticketsCollection,
  ticketsStatesCollection,
  ordersStatesCollection,
  productsCollection,
  ticketsDownloadsCollection,
  tagsCollection,
  devicesCollection,
  projectsCollection,
  teamsCollection,
  webhooksCollection,
  integrationsCollection,
  campaignsBudgetsCollection,
  serverTimestamp,
};
