var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "tickets" } },
    [
      _vm.getAgent &&
      _vm.getAgents &&
      _vm.getTickets &&
      _vm.getActiveTicketStates
        ? _c(
            "div",
            [
              _c(
                "div",
                [
                  _c("TicketHeader", {
                    attrs: {
                      query: _vm.query,
                      "show-filters": _vm.showFilters,
                      "custom-filters": _vm.customFilters
                    },
                    on: {
                      "update:query": function($event) {
                        _vm.query = $event
                      },
                      "update:showFilters": function($event) {
                        _vm.showFilters = $event
                      },
                      "update:show-filters": function($event) {
                        _vm.showFilters = $event
                      },
                      "update:customFilters": function($event) {
                        _vm.customFilters = $event
                      },
                      "update:custom-filters": function($event) {
                        _vm.customFilters = $event
                      },
                      loadTickets: _vm.loadTickets,
                      applyFilter: _vm.applyFilter
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "px-4 hidden-md-and-up" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            to: { name: "ticket_register" },
                            color: "primary",
                            "elevation-12": "",
                            block: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "16" } },
                                [_vm._v("fa-plus")]
                              ),
                              _vm._v(" Agregar ")
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-5 pb-5 rounded-lg" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.tickets === null,
                      indeterminate: true,
                      absolute: "",
                      top: "",
                      color: "secondary accent-4"
                    }
                  }),
                  _vm._v(" "),
                  _vm.getTickets && _vm.tickets && _vm.states
                    ? _c(
                        "v-col",
                        { staticClass: "px-4 py-4", attrs: { cols: "12" } },
                        [
                          _c("v-data-table", {
                            staticClass: "text-headers-colors",
                            attrs: {
                              headers: _vm.headers,
                              items: _vm.tickets,
                              "item-class": function(item) {
                                return item.id == _vm.getSelectedTicketId
                                  ? "accent"
                                  : ""
                              },
                              "items-per-page": 50,
                              dense: "",
                              "sort-by": "",
                              "mobile-breakpoint": 0,
                              "hide-default-footer": ""
                            },
                            on: {
                              "click:row": function(item, row) {
                                return _vm.showTicketDetail({
                                  ticketId: item.id
                                })
                              }
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item.created_at",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$formatTimestamp(
                                              item.created_at,
                                              false,
                                              true
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.last_activity",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$formatTimestamp(
                                              item.last_activity,
                                              false,
                                              true
                                            )
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.state",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.states[item.state]
                                              ? _vm.states[item.state].name
                                              : ""
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.created_uid",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            item.created_uid !== "none" &&
                                              item.created_uid
                                              ? _vm.getAllAgentsMap[
                                                  item.created_uid
                                                ].name
                                              : "-"
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.agent_id",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            item.agent_id !== "none" &&
                                              item.agent_id
                                              ? _vm.getAllAgentsMap[
                                                  item.agent_id
                                                ].name
                                              : "Sin asignar"
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "item.operations",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      item.phone
                                        ? _c("BtnWhatsapp", {
                                            attrs: { number: item.phone }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.email
                                        ? _c("BtnGmail", {
                                            attrs: { email: item.email }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            to: {
                                              name: "ticket_detail",
                                              params: { id: item.id }
                                            },
                                            target: "_blank",
                                            text: "",
                                            icon: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "primary",
                                                dark: "",
                                                small: ""
                                              }
                                            },
                                            [_vm._v("fas fa-external-link-alt")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2590096407
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-0" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12", md: "6" }
                                },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.total_page,
                                      "prev-icon": "fas fa-angle-left",
                                      "next-icon": "fas fa-angle-right",
                                      "total-visible": "6"
                                    },
                                    on: { input: _vm.loadTickets },
                                    model: {
                                      value: _vm.page,
                                      callback: function($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-end",
                                  attrs: { cols: "12", md: "3" }
                                },
                                [
                                  _c(
                                    "h5",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n              Total de tickets: " +
                                          _vm._s(
                                            _vm.total_tickets -
                                              _vm.getTickets.length +
                                              _vm.tickets.length
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: {
                    to: { name: "ticket_register" },
                    fixed: "",
                    bottom: "",
                    right: "",
                    "x-large": "",
                    color: "primary",
                    "elevation-12": "",
                    rounded: ""
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { size: "16" } },
                        [_vm._v("fa-plus")]
                      ),
                      _vm._v(" Agregar ")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }