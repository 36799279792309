export default {
  getTicketStates(state) {
    return state.ticketStates;
  },
  getTicketStatesMap(state) {
    return state.ticketStatesMap;
  },
  getActiveTicketStates(state) {
    let states = [];
    if (state.ticketStates) {
      states = [];
      for (let t in state.ticketStates) {
        if (state.ticketStates[t].active) states.push(state.ticketStates[t]);
      }
    }
    return states;
  },
  getOrderStates(state) {
    return state.orderStates;
  },
  getOrderStatesMap(state) {
    return state.orderStatesMap;
  },
};
