var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "contacts" } },
    [
      _vm.getAgent && _vm.getAgents && _vm.getContacts
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "d-flex align-center pt-4 pt-sm-10" },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "6", md: "9" } }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "text-h4 primary--text mb-0 font-weight-black hidden-sm-and-down"
                      },
                      [_vm._v("Contactos")]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex justify-md-end justify-space-between",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "mr-0 hidden-md-and-up",
                                          attrs: { icon: "", large: "" },
                                          on: {
                                            click: function($event) {
                                              _vm.showFilters = !_vm.showFilters
                                            }
                                          }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [_vm._v("fas fa-filter")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1257802151
                          )
                        },
                        [
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.showFilters ? "Ocultar" : "Mostrar") +
                                " filtros"
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-4 my-auto",
                          attrs: {
                            loading: _vm.loadingExport,
                            outlined: "",
                            color: "primary",
                            dark: "",
                            normal: ""
                          },
                          on: { click: _vm.exportData }
                        },
                        [
                          _vm._v("\n          Descargar\n          "),
                          _c("v-icon", { attrs: { right: "", size: "16" } }, [
                            _vm._v("fa-file-download")
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "", bottom: "", left: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        {
                                          staticClass: "my-auto",
                                          attrs: { icon: "", outlined: "" }
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { size: "16" } },
                                          [_vm._v("fa fa-ellipsis-v")]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2623885091
                          )
                        },
                        [
                          _vm._v(" "),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item",
                                { attrs: { to: "/contacts/importer" } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Importar contactos")
                                  ])
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item",
                                {
                                  attrs: { to: "/contacts/importer-activities" }
                                },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v("Importar actividades")
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "hidden-lg-and-up" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: { name: "contact_register" },
                        color: "primary",
                        "elevation-12": "",
                        block: ""
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-2", attrs: { size: "16" } },
                            [_vm._v("fa-plus")]
                          ),
                          _vm._v(" Agregar ")
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "d-flex align-center py-2 py-sm-4" },
                [
                  _vm._l(_vm.customFilters, function(f, i) {
                    return [
                      _vm.showFilters
                        ? _c(
                            "v-col",
                            {
                              key: i,
                              staticClass: "py-0 mb-1 mb-sm-0",
                              attrs: {
                                cols: f.flex_xs || "12",
                                sm: f.flex_md || "6",
                                md: f.flex_md || "3"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass:
                                    "py-2 px-4 elevation-3 rounded-lg"
                                },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "px-1",
                                    attrs: {
                                      value: f.value,
                                      items: f.items,
                                      label: f.name,
                                      readonly:
                                        f.readonly_advisor &&
                                        _vm.getAgent.role ===
                                          _vm.$roles.ADVISOR,
                                      "item-text": f.show_attr || "name",
                                      "item-value": "id"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.applyFilter(f.id, $event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    {
                      staticClass: "py-0",
                      attrs: { cols: "12", sm: "6", md: "3" }
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "px-4 elevation-3 rounded-lg py-1" },
                        [
                          _c("v-text-field", {
                            staticClass: "d-flex align-center imput-icon",
                            attrs: {
                              label: "Buscar",
                              dense: "",
                              solo: "",
                              "single-line": "",
                              "hide-details": "",
                              flat: "",
                              "append-icon": "fa fa-search"
                            },
                            on: { input: _vm.loadContacts },
                            model: {
                              value: _vm.query,
                              callback: function($$v) {
                                _vm.query = $$v
                              },
                              expression: "query"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "v-card",
                { staticClass: "mb-5 pb-5 rounded-lg" },
                [
                  _c("v-progress-linear", {
                    attrs: {
                      active: _vm.contacts === null,
                      indeterminate: true,
                      absolute: "",
                      top: "",
                      color: "secondary accent-4"
                    }
                  }),
                  _vm._v(" "),
                  _vm.getContacts && _vm.contacts
                    ? _c(
                        "v-col",
                        { staticClass: "pa-4", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "text-headers-colors",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.contacts,
                                "items-per-page": 50,
                                "mobile-breakpoint": 0,
                                "sort-by": "",
                                dense: "",
                                "hide-default-footer": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item.created_at",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$formatTimestamp(
                                                item.created_at
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.agent_id",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              item.agent_id !== "none" &&
                                                item.agent_id
                                                ? _vm.getAgents[item.agent_id]
                                                  ? _vm.getAgents[item.agent_id]
                                                      .name
                                                  : item.agent_id
                                                : "Sin asignar"
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    }
                                  },
                                  {
                                    key: "item.operations",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              text: "",
                                              icon: "",
                                              to: {
                                                name: "contact_detail",
                                                params: { id: item.id }
                                              },
                                              target: "_blank"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  color: "primary",
                                                  dark: "",
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "fas fa-external-link-alt"
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                2575755979
                              )
                            },
                            [
                              _vm._v(" "),
                              _vm._v(" "),
                              _vm._v(" "),
                              _c("v-alert", {
                                attrs: {
                                  value: true,
                                  color: "error",
                                  icon: "warning"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "no-results",
                                      fn: function() {
                                        return [
                                          _vm._v(
                                            'Your search for "' +
                                              _vm._s(_vm.search) +
                                              '" found no results.'
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  2478838959
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "pa-0" },
                            [
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-center",
                                  attrs: { cols: "12", md: "6" }
                                },
                                [
                                  _c("v-pagination", {
                                    attrs: {
                                      length: _vm.total_page,
                                      "prev-icon": "fas fa-angle-left",
                                      "next-icon": "fas fa-angle-right",
                                      "total-visible": "6"
                                    },
                                    on: { input: _vm.loadContacts },
                                    model: {
                                      value: _vm.page,
                                      callback: function($$v) {
                                        _vm.page = $$v
                                      },
                                      expression: "page"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "d-flex align-center justify-end",
                                  attrs: { cols: "12", md: "3" }
                                },
                                [
                                  _c(
                                    "h5",
                                    { staticClass: "font-weight-thin" },
                                    [
                                      _vm._v(
                                        "\n              Total de contactos: " +
                                          _vm._s(
                                            _vm.total_contacts -
                                              _vm.getContacts.length +
                                              _vm.contacts.length
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "hidden-sm-and-down",
                  attrs: {
                    to: { name: "contact_register" },
                    fixed: "",
                    bottom: "",
                    right: "",
                    "x-large": "",
                    color: "primary",
                    "elevation-12": "",
                    rounded: ""
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { size: "16" } },
                        [_vm._v("fa-plus")]
                      ),
                      _vm._v(" Agregar ")
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _c("v-container", [_c("ShowLoading")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }