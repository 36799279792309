<template>
  <v-navigation-drawer v-model="localDrawer" temporary right app width="400">
    <v-card class="pt-4 elevation-0">
      <div class="d-flex justify-end hidden-md-and-up">
        <v-btn class="ma-1" x-small outlined fixed fab right @click.stop.prevent="localDrawer = false">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </div>
    </v-card>
    <template v-if="getTicket">
      <v-toolbar flat>
        <v-list-item two-line class="pa-0">
          <v-list-item-avatar size="48" color="accent" class="pa-2">
            <span class="white--text subhead"> {{ avatarText }}</span>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="`${getTicket.full_name}`"></v-list-item-title>
            <v-list-item-subtitle v-text="getTicket.email"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <template v-slot:extension>
          <v-tabs v-model="tab" slider-color="primary">
            <v-tab key="1" href="#tab-1">información</v-tab>
            <v-tab key="2" href="#tab-2">Actividad</v-tab>
            <v-tab key="3" href="#tab-3">Relacionado</v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item key="1" value="tab-1">
          <div v-if="!editInformation" class="text-right px-4">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" fab x-small dark v-on="on">
                  <v-icon size="16" @click.stop.prevent="editInformation = true">fas fa-pencil-alt</v-icon>
                </v-btn>
                <!-- <v-btn size="16" fab icon small class="pa-4" color="primary">
                </v-btn> -->
              </template>
              <span>Editar información</span>
            </v-tooltip>
          </div>
          <v-form ref="formInformation" lazy-validation>
            <div class="fill-height text-center px-3 pt-2">
              <v-col v-if="editInformation" cols="12">
                <h4>Editar información de la Oportunidad</h4>
              </v-col>
              <v-col v-if="editInformation" cols="12">
                <TicketForm action="update" @cancelInformation="cancelInformation" />
              </v-col>
              <v-card v-else class="mb-4">
                <template v-for="(ticketField, i) in ticketFields">
                  <v-list :key="i" class="text-left">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title class="font-weight-regular">
                          {{ getTicket[ticketField.field] }}
                          <BtnWhatsapp
                            v-if="ticketField.field === 'phone' && getTicket.phone"
                            :number="getTicket.phone"
                          />
                          <BtnGmail v-if="ticketField.field === 'email' && getTicket.email" :email="getTicket.email" />
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ ticketField.label }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mx-2"></v-divider>
                  </v-list>
                </template>
              </v-card>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item key="2" value="tab-2">
          <v-card class="pa-2 py-4 ma-2">
            <CommentForm :ticket-id="getSelectedTicketId" :col-fixed="12" />
          </v-card>
          <v-card class="pa-2 py-4 ma-2">
            <Tags />
            <v-divider class="mt-4"></v-divider>
            <Timeline :ticket-id="getSelectedTicketId" />
          </v-card>
        </v-tab-item>
        <v-tab-item key="3" value="tab-3">
          <v-card class="ma-2 pa-2">
            <div class="d-flex justify-space-between">
              <p class="font-weight-black"><span v-if="editState">EDITAR</span> ESTADO</p>
            </div>
            <v-form ref="formState" lazy-validation>
              <v-row class="fill-height text-center px-3 pt-2">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="state"
                    :value="getTicket.state"
                    :items="getActiveTicketStates"
                    :readonly="getTicketStatesMap[getTicket.state].final"
                    :rules="stateCustomRules"
                    style="font-size: 15px;"
                    item-text="name"
                    item-value="id"
                    label="Estado"
                    class="pt-2"
                  ></v-autocomplete>
                </v-col>
                <v-col v-if="editState" cols="12">
                  <v-btn :loading="loadSaveState" small rounded color="primary" @click="saveState">Guardar</v-btn>
                  <v-btn small rounded @click="cancelState">Cancelar</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card>
          <v-card v-if="getProducts && getProducts.length && products" class="ma-2 pa-2">
            <div class="d-flex justify-space-between">
              <p class="font-weight-black">
                <span v-if="editProducts">EDITAR</span>
                PRODUCTOS RELACIONADOS
              </p>
              <div v-if="getTicket.state !== $ticketStates.ORDEN_CREADA" class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn icon small v-on="on" @click="addItemProduct">
                      <v-icon small>fas fa-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar producto</span>
                </v-tooltip>
              </div>
            </div>
            <v-form ref="formProducts" lazy-validation>
              <ItemsProducts :products.sync="products" :action.sync="actionItemsProducts" />
              <v-col v-if="editProducts" cols="12" class="text-center">
                <v-btn :loading="loadSaveProducts" small rounded color="primary" @click="saveTicketProducts"
                  >Guardar</v-btn
                >
                <v-btn small rounded @click="cancelProducts">Cancelar</v-btn>
              </v-col>
            </v-form>
          </v-card>
          <v-card class="ma-2 pa-2">
            <p class="font-weight-black">ASISTENCIA</p>
            <v-col cols="12" class="d-flex">
              <div v-if="getTicket.agent_id && !loadSaveAgent">
                <v-avatar size="48px" color="black">
                  <img
                    v-if="getAllAgentsMap[getTicket.agent_id].photo"
                    :src="getAllAgentsMap[getTicket.agent_id].photo"
                    alt="Avatar"
                  />
                  <span v-else class="white--text headline">
                    {{ getAllAgentsMap[getTicket.agent_id].full_name.substring(0, 1) }}
                  </span>
                </v-avatar>
              </div>
              <div v-else>
                <v-avatar size="50px" color="black">
                  <span class="white--text headline">-</span>
                </v-avatar>
              </div>
              <v-text-field
                v-if="getAgent.role === $roles.ADVISOR || getTicketStatesMap[getTicket.state].final"
                v-model="getAgent.full_name"
                label="Encargado"
                class="pl-2"
                readonly
              >
              </v-text-field>
              <v-autocomplete
                v-else
                v-model="agentTicket"
                :items="getAgentsToArray"
                :disabled="loadSaveAgent"
                item-text="full_name"
                item-value="uid"
                :value="getTicket.agent_id"
                label="Encargado"
                placeholder="Sin asignar"
                class="pl-2 w95"
              ></v-autocomplete>
            </v-col>
          </v-card>
          <v-card v-if="!hideOriginForAdvisors || getAgent.role !== $roles.ADVISOR" class="ma-2 pa-2">
            <p class="font-weight-black">FUENTE</p>

            <v-col class="size" cols="12">
              <div>
                Obtenido desde <strong>{{ getTicket.origin }}</strong>
              </div>
            </v-col>
            <v-col v-if="getTicket.created_uid" class="d-flex" cols="12">
              <div>
                <v-avatar size="50px" color="black">
                  <img
                    v-if="getAllAgentsMap[getTicket.created_uid].photo"
                    :src="getAllAgentsMap[getTicket.created_uid].photo"
                    alt="Avatar"
                  />
                  <span v-else class="white--text headline">
                    {{ getAllAgentsMap[getTicket.created_uid].full_name.substring(0, 1) }}
                  </span>
                </v-avatar>
              </div>
              <v-text-field
                :value="getAllAgentsMap[getTicket.created_uid].full_name"
                label="Registrado por:"
                class="pl-2 w95"
                readonly
              ></v-text-field>
            </v-col>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "Config/config";

import TicketForm from "@/components/forms/TicketForm";
import Timeline from "@/components/tickets/Timeline";
import CommentForm from "@/components/tickets/CommentForm";
import Tags from "./options/Tags";
import BtnWhatsapp from "@/components/buttons/BtnWhatsapp";
import BtnGmail from "@/components/buttons/BtnGmail";
import ItemsProducts from "@/components/items/Products";

export default {
  name: "TicketDetailDrawer",
  components: {
    TicketForm,
    Tags,
    Timeline,
    CommentForm,
    BtnWhatsapp,
    BtnGmail,
    ItemsProducts,
  },
  data() {
    return {
      tab: "tab-2",
      ticket: {
        id: this.getSelectedTicketId,
      },
      tabs: [0, 1, 2],
      ticketFields: [],
      editInformation: false,
      editContact: false,
      editProducts: false,
      loadProducts: false,
      loadSaveInformation: false,
      loadSaveAgent: false,
      loadSaveState: false,
      loadSaveProducts: false,
      state: null,
      products: null,
      resetProducts: false,
      hideOriginForAdvisors: config.hideOriginForAdvisors,
    };
  },
  computed: {
    ...mapGetters([
      "getSelectedTicketId",
      "getShowTicketDetailDrawer",
      "getAgent",
      "getAllAgentsMap",
      "getAgentsToArray",
      "getTicket",
      "getActiveTicketStates",
      "getTicketStatesMap",
      "getTimeline",
      "getContact",
      "getSchemaTicketForm",
      "getProducts",
    ]),
    editState() {
      return this.getTicket && this.getTicket.state !== this.state;
    },
    localDrawer: {
      get() {
        // Carga la data cuando el drawer se abre.
        if (this.getShowTicketDetailDrawer) {
          this.mountedNow();
        }
        return this.getShowTicketDetailDrawer;
      },
      set(v) {
        if (!v) {
          this.ticketFields = [];
          this.editInformation = false;
          this.editContact = false;
          this.loadSaveInformation = false;
          this.loadSaveTags = false;
          this.loadSaveAgent = false;
          this.tags = [];
          this.state = 0;
          // this.showTicketDetail({ ticketId: null });
          this.hideTicketDetail();
        }
      },
    },
    agentTicket: {
      get() {
        return this.getTicket != null ? this.getTicket.agent_id : 0;
      },
      set(value) {
        if (this.getTicket.agent_id !== value) this.saveAgent(value);
      },
    },
    entity() {
      return this.getContact || this.getTicket;
    },
    avatarText() {
      return `${this.getTicket.name.charAt(0)}${
        this.getTicket.last_name ? this.getTicket.last_name.charAt(0) : ""
      }`.toUpperCase();
    },
    stateCustomRules() {
      return [
        (v) =>
          v !== this.$ticketStates.ORDEN_CREADA ||
          (v === this.$ticketStates.ORDEN_CREADA && this.getTicket.products && this.getTicket.products.length > 0) ||
          "Es necesario tener productos relacionados para crear una orden",
      ];
    },
    allowUpdateItemsProducts() {
      return this.getTicket.state !== this.$ticketStates.ORDEN_CREADA;
    },
    actionItemsProducts() {
      return this.allowUpdateItemsProducts ? "update" : "read";
    },
  },
  watch: {
    "getTicket.state"(value) {
      if (value === this.$ticketStates.ORDEN_CREADA) {
        this.resetProducts = true;
        this.editProducts = false;
        this.products = (this.getTicket.products || []).map((p) => ({ ...p }));
      }
    },
    getTicket(value) {
      if (!this.products) this.products = (value.products || []).map((p) => ({ ...p }));

      if (!this.state) this.state = value.state;
    },
    products: {
      deep: true,
      handler(value, oldValue) {
        if (oldValue && this.allowUpdateItemsProducts && !this.resetProducts) this.editProducts = true;
        else {
          this.editProducts = false;
          this.resetProducts = false;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "showTicketDetail",
      "hideTicketDetail",
      "getTicketById",
      "getTeamSchema",
      "setTicketState",
      "setTicketAgent",
      "setTicketProducts",
    ]),
    async mountedNow() {
      await this.getTicketById({ ticket_id: this.getSelectedTicketId });

      this.initSchemas();
    },
    async initSchemas() {
      let ticketFormSchema = config.ticketFormSchema;

      if (this.getAgent.role === this.$roles.ENTREPRENEUR) {
        await this.getTeamSchema({ id: this.getAgent.team_id, schemaIds: ["ticket_form"] });
        ticketFormSchema = this.getSchemaTicketForm;
      }

      this.ticketFields = this.loadSchemaFields(ticketFormSchema);
    },
    loadSchemaFields(schema) {
      let fields = [];
      for (let field in schema) {
        if (schema[field].show_on_detail) {
          fields.push({ field: field, ...schema[field] });
        }
      }
      return fields;
    },
    async saveState() {
      try {
        this.loadSaveState = true;

        if (this.$refs.formState.validate())
          await this.setTicketState({
            ticket_id: this.getTicket.id,
            state: this.state,
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.loadSaveState = false;
      }
    },
    cancelState() {
      this.state = this.getTicket.state;
    },
    cancelInformation() {
      this.editInformation = false;
    },
    cancelContact() {
      this.editContact = false;
    },
    async saveAgent(agent_id) {
      try {
        this.loadSaveAgent = true;

        await this.setTicketAgent({ ticket_id: this.getTicket.id, agent_id: agent_id });
      } catch (error) {
        console.error("ticket/saveAgent: ", error);
        this.agentTicket = this.getTicket.agent_id;
      } finally {
        this.loadSaveAgent = false;
      }
    },
    addItemProduct() {
      this.products.push({
        id: "",
        name: "",
        price: 0,
        quantity: 1,
      });
    },
    async saveTicketProducts() {
      try {
        this.loadSaveProducts = true;

        if (this.$refs.formProducts.validate()) {
          await this.setTicketProducts({ id: this.getTicket.id, products: this.products });

          this.editProducts = false;
        }
      } catch (error) {
        console.error("saveTicketProducts: ", error);
      } finally {
        this.loadSaveProducts = false;
      }
    },
    cancelProducts() {
      this.resetProducts = true;
      this.products = (this.getTicket.products || []).map((p) => ({ ...p }));
    },
  },
};
</script>
