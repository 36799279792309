export default {
  getProducts(state) {
    return state.products;
  },
  getActiveProducts(state) {
    return state.products.filter((p) => p.active);
  },
  getProductsMap(state) {
    return state.productsMap;
  },
};
