<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="800">
    <v-card class="py-3">
      <v-card-title class="headline">
        <div>
          <v-img :src="require(`@/assets/social_icons/${id}.png`)" width="20px" class="mr-2"></v-img>
        </div>
        <span class="ml-0">SendGrid</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog">
          <v-icon small>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row v-if="!getIntegrationSendGrid">
          <v-col cols="12">
            <h4 class="font-weight-thin">
              La integración con SendGrid le permitirá realizar algunas acciones como el enviar correo a los agentes que
              son asignados a una oportunidad, también puede hacer que un mensaje de correo se convierta en una
              oportunidad dentro de la plataforma.
            </h4>
          </v-col>
          <v-col cols="12" class="pt-2">
            <v-alert dense text type="info" class="caption">
              Antes de empezar, le dejamos una guía para configuraciones previas a la
              <a
                href="https://docs.google.com/document/d/1C1yTnbAgr8Mf9jD4B-TLGcLjViT1zt_9TxfnxfKBktg/edit?usp=sharing"
                target="_blank"
                >integración de SendGrid en Apolo CRM</a
              >. Recuerde que el nombre de la clave API ha crear será: <strong>Apolo CRM</strong>.
            </v-alert>
          </v-col>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">1</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Deberá ingresar el <span class="font-weight-bold">token</span> de la clave de API
                  <span class="font-weight-bold">Apolo CRM</span> creada en SendGrid.
                </h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-form ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="access_token"
                        label="Token"
                        placeholder=" "
                        type="password"
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
              <v-col v-if="config_apikey_error" cols="12">
                <v-alert text type="error">
                  {{ config_apikey_error }}
                </v-alert>
              </v-col>
              <v-col cols="12" class="text-right">
                <v-btn rounded text @click="closeDialog">Cancelar</v-btn>
                <v-btn color="primary" rounded :loading="load" @click="save">
                  Verificar y guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="d-flex">
            <span class="circle primary white--text mr-2">2</span>
            <v-row class="pr-5">
              <v-col cols="12" class="pt-0">
                <h4 class="font-weight-thin">
                  Puedes configurar y activar las siguientes acciones para tu Apolo CRM.
                </h4>
              </v-col>
              <v-col cols="12">
                <v-expansion-panels dense>
                  <!-- Op1: send mails -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="w100">Enviar correo a los asesores cuando son asignados a alguna oportunidad</span>
                      <div class="text-center px-5 caption">
                        <span v-if="getIntegrationSendGrid.op_send_mail" class="success--text">Activada</span>
                        <span v-else class="accent--text">Configuración necesaria</span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="getIntegrationSendGrid.op_send_mail">
                        <v-col v-if="send_mail_error" cols="12">
                          <v-alert text type="error">
                            {{ send_mail_error }}
                          </v-alert>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            float
                            outlined
                            rounded
                            small
                            color="red"
                            :loading="load"
                            @click="removeConfig('send_mail')"
                            >Eliminar configuración</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="12">
                          <h3 class="mb-3">Configuración necesaria</h3>
                          <h4 class="font-weight-thin">
                            Está integración hará que se envíe correos tanto al asignado de la oportunidad, como a los
                            supervisores del equipo al que pertenezca si así desea.
                          </h4>
                        </v-col>
                        <v-col cols="12" class="pt-2">
                          <v-alert dense text type="info" class="caption">
                            Antes de empezar, deberá otorgar el acceso de <strong>enviar correo</strong> a la clave API
                            Apolo CRM creada y también <strong>autorizar el dominio {{ domain_authorized }}</strong
                            >. Aquí encontrará una
                            <a
                              href="https://docs.google.com/document/d/1eWyN1mcYL4EbUUuouRw6h7WFl6y326VJbAwYuDY6maE/edit?usp=sharing"
                              target="_blank"
                              >guía para configuraciones para el envío de corrreos con SendGrid</a
                            >.
                          </v-alert>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <span class="circle primary white--text mr-2">1</span>
                          <v-row class="pr-5">
                            <v-col cols="12" class="pt-0">
                              <h4 class="font-weight-thin">
                                Deberá verficar si el <span class="font-weight-bold">correo electrónico</span> ya se
                                encuentra autorizado en la cuenta de SendGrid, para que pueda enviar los correos.
                              </h4>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-form ref="formVerifySendMail" lazy-validation>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="email"
                                      label="Correo electrónico"
                                      placeholder=" "
                                      :rules="rules.email"
                                      readonly
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" class="text-right">
                                    <span v-if="account_verification_error" class="error--text mr-4">{{
                                      account_verification_error
                                    }}</span>
                                    <span v-if="account_verified" class="green--text mr-4 font-weight-bold"
                                      ><i class="fas fa-check"></i> Verificado</span
                                    >
                                    <v-btn
                                      rounded
                                      color="accent"
                                      outlined
                                      :disabled="account_verified"
                                      :loading="load_verify"
                                      @click="verifySendMail"
                                      >Verificar</v-btn
                                    >
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <span class="circle primary white--text mr-2">2</span>
                          <v-row class="pr-5">
                            <v-col cols="12" class="pt-0">
                              <h4 class="font-weight-thin">
                                Seleccione las opciones que desea para tener en cuenta en el envío automático de
                                correos. Recuerde que el envío al
                                <span class="font-weight-bold">agente asignado</span> es obligatorio.
                              </h4>
                            </v-col>
                            <v-col cols="12" class="pt-0">
                              <div class="d-flex">
                                <v-checkbox
                                  v-model="send_agent_assigned"
                                  :value="true"
                                  readonly
                                  color="accent"
                                  hide-details
                                ></v-checkbox>
                                <span class="mt-2">Envío al agente asignado</span>
                              </div>
                              <div class="d-flex">
                                <v-checkbox
                                  v-model="send_supervisors"
                                  :value="true"
                                  color="accent"
                                  hide-details
                                ></v-checkbox>
                                <span class="mt-2">Envío a supervisores del equipo del agente asignado</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="send_mail_error" cols="12">
                          <v-alert text type="error">
                            {{ send_mail_error }}
                          </v-alert>
                        </v-col>
                        <v-col cols="12" class="text-right">
                          <v-btn
                            color="primary"
                            rounded
                            :disabled="!account_verified"
                            :loading="load"
                            @click="saveConfigSendMail"
                          >
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Op2: receive mails -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <span class="w100">Recibir correos y convertirlos a oportunidades</span>
                      <div class="text-center px-5 caption">
                        <span v-if="getIntegrationSendGrid.op_receive_mail" class="success--text">Activada</span>
                        <span v-else class="caption accent--text">Configuración necesaria</span>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row v-if="getIntegrationSendGrid.op_receive_mail" class="px-3">
                        <v-col v-if="receive_mail_error" cols="12">
                          <v-alert text type="error">
                            {{ receive_mail_error }}
                          </v-alert>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            float
                            outlined
                            rounded
                            small
                            color="red"
                            :loading="load"
                            @click="removeConfig('receive_mail')"
                            >Eliminar configuración</v-btn
                          >
                        </v-col>
                      </v-row>
                      <v-row v-else>
                        <v-col cols="12">
                          <h3 class="mb-3">Configuración necesaria</h3>
                          <h4 class="font-weight-thin">
                            Está integración hará que cada vez que le envíen un correo a su bandeja de entrada de un
                            correo con dominio autorizado, esto convertirá en una nueva oportunidad en Apolo CRM.
                          </h4>
                        </v-col>
                        <v-col cols="12" class="pt-2">
                          <v-alert dense text type="info" class="caption">
                            Antes de empezar, deberá otorgar el acceso de <strong>análisis entrante</strong> y
                            <strong>autenticación del remitente</strong> la clave API Apolo CRM creada, así como
                            configurar un <strong>dominio autorizado</strong>. Aquí encontrará una
                            <a
                              href="https://docs.google.com/document/d/1JpNmp6XnZENX1YUI64B8hOkXESCAzp6Hj2c7T8zW0Wk/edit?usp=sharing"
                              target="_blank"
                              >guía para recepción de correos entrantes con SendGrid</a
                            >.
                          </v-alert>
                        </v-col>
                        <v-col cols="12" class="d-flex">
                          <span class="circle primary white--text mr-2">1</span>
                          <v-row class="pr-5">
                            <v-col cols="12" class="pt-0">
                              <h4 class="font-weight-thin">
                                Deberá ingresar el <span class="font-weight-bold">dominio autorizado</span> que
                                configuró en SendGrid.
                              </h4>
                            </v-col>
                            <v-col cols="12" class="py-0">
                              <v-form ref="formVerifyDomainAuthorized" lazy-validation>
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field
                                      v-model="domain_authorized"
                                      label="Dominio autorizado"
                                      placeholder=" "
                                      readonly
                                      :rules="rules.required"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-form>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col v-if="receive_mail_error" cols="12">
                          <v-alert text type="error">
                            {{ receive_mail_error }}
                          </v-alert>
                        </v-col>
                        <v-col cols="12" class="text-right">
                          <v-btn color="primary" rounded :loading="load" @click="saveConfigReceiveMail">
                            Guardar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { siteURL } from "Config/config";

export default {
  name: "SendGridModal",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: "sendgrid",
      access_token: "",
      op_send_mail: false,
      op_receive_mail: false,
      email: `no-repply@${siteURL.substring(8)}`,
      email_verified: "",
      send_agent_assigned: true,
      send_supervisors: true,
      account_verified: false,
      domain_authorized: siteURL.substring(8),
      account_verification_error: null,
      config_apikey_error: null,
      send_mail_error: null,
      receive_mail_error: null,
      load_verify: false,
      load: false,
      rules: {
        required: [(v) => !!v || "Campo requerido"],
        email: [
          (v) => !!v || "Campo requerido",
          (v) =>
            // eslint-disable-next-line
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v,
            ) || "Correo electrónico no válido",
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["getIntegrationSendGrid"]),
  },
  watch: {
    email(value) {
      if (this.email_verified && this.email_verified !== value) this.account_verified = false;
    },
    getIntegrationSendGrid(value) {
      if (value) {
        this.op_send_mail = value.op_send_mail;
        this.op_receive_mail = value.op_receive_mail;
      }
    },
  },
  methods: {
    ...mapActions([
      "verifySendGridAPIKey",
      "saveIntegration",
      "updateIntegration",
      "verifySendGridSendMail",
      "verifySendGridConfigInboundParse",
      "removeSendGridConfig",
    ]),
    async save() {
      try {
        this.load = true;
        this.config_apikey_error = null;

        const response = await this.verifySendGridAPIKey({ access_token: this.access_token });
        await this.saveIntegration({ id: "sendgrid", data: response.data });
      } catch (error) {
        this.config_apikey_error = error.message || "Hubo problemas al verifivar la clave de API Apolo CRM en SendGrid";
      } finally {
        this.load = false;
      }
    },
    async verifySendMail() {
      try {
        this.load_verify = true;
        this.account_verification_error = null;

        if (this.email !== `no-repply@${siteURL.substring(8)}`) throw new Error("El correo electronico es inválido");

        if (this.$refs.formVerifySendMail.validate()) {
          const response = await this.verifySendGridSendMail({
            email: this.email,
            access_token: this.getIntegrationSendGrid.access_token,
          });

          this.account_verified = true;
          this.email_verified = response.data.email;
        }
      } catch (error) {
        this.account_verification_error = error.message || "Hubo problemas al verificar el envío de correo";
      } finally {
        this.load_verify = false;
      }
    },
    async saveConfigSendMail() {
      try {
        this.load = true;
        this.send_mail_error = null;

        await this.updateIntegration({
          id: "sendgrid",
          data: {
            email: this.email_verified,
            send_agent_assigned: this.send_agent_assigned,
            send_supervisors: this.send_supervisors,
            op_send_mail: true,
          },
        });
      } catch (error) {
        this.send_mail_error = error.message || "Hubo problemas al configurar el envío de correos con SendGrid";
      } finally {
        this.load = false;
      }
    },
    async saveConfigReceiveMail() {
      try {
        this.load = true;
        this.receive_mail_error = null;

        if (this.domain_authorized !== siteURL.substring(8)) throw new Error("Dominio incorrecto");

        const response = await this.verifySendGridConfigInboundParse({
          access_token: this.getIntegrationSendGrid.access_token,
          domain_authorized: this.domain_authorized,
        });

        await this.updateIntegration({
          id: "sendgrid",
          data: {
            op_receive_mail: true,
            ...response.data,
          },
        });
      } catch (error) {
        this.receive_mail_error = error.message || "Hubo problemas al configurar la recepción de correos con SendGrid";
      } finally {
        this.load = false;
      }
    },
    async removeConfig(type) {
      try {
        this.load = true;
        this.send_mail_error = null;
        this.receive_mail_error = null;

        await this.removeSendGridConfig({ config_type: type });
      } catch (error) {
        const message = error.message || "Hubo problemas al eliminar la configuración de SendGrid";
        if (type === "send_email") this.send_mail_error = message;
        if (type === "receive_email") this.receive_mail_error = message;
      } finally {
        this.load = false;
      }
    },
    closeDialog() {
      this.$emit("update:dialog", false);
    },
  },
};
</script>
