export function formatFilters(data = {}) {
  let filters = "";
  let add_conector = false;

  Object.keys(data).forEach((filter_id) => {
    if (data[filter_id].value) {
      filters += `${add_conector ? " AND " : ""}${filter_id !== "products.id" ? filter_id : `"${filter_id}"`}:"${
        data[filter_id].value
      }"`;
      add_conector = true;
    }
  });

  return filters;
}
