<template>
  <v-app id="tickets">
    <div v-if="getAgent && getAgents && getTickets && getActiveTicketStates">
      <div>
        <TicketHeader
          :query.sync="query"
          :show-filters.sync="showFilters"
          :custom-filters.sync="customFilters"
          @loadTickets="loadTickets"
          @applyFilter="applyFilter"
        />
        <div class="px-4 hidden-md-and-up">
          <v-btn :to="{ name: 'ticket_register' }" color="primary" elevation-12 block>
            <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
          </v-btn>
        </div>
      </div>

      <v-card class="mb-5 pb-5 rounded-lg">
        <v-progress-linear
          :active="tickets === null"
          :indeterminate="true"
          absolute
          top
          color="secondary accent-4"
        ></v-progress-linear>
        <v-col v-if="getTickets && tickets && states" cols="12" class="px-4 py-4">
          <v-data-table
            :headers="headers"
            :items="tickets"
            :item-class="(item) => (item.id == getSelectedTicketId ? 'accent' : '')"
            :items-per-page="50"
            dense
            sort-by
            :mobile-breakpoint="0"
            hide-default-footer
            class="text-headers-colors"
            @click:row="(item, row) => showTicketDetail({ ticketId: item.id })"
          >
            <template v-slot:item.created_at="{ item }">
              {{ $formatTimestamp(item.created_at, false, true) }}
            </template>
            <template v-slot:item.last_activity="{ item }">
              {{ $formatTimestamp(item.last_activity, false, true) }}
            </template>
            <template v-slot:item.state="{ item }">
              {{ states[item.state] ? states[item.state].name : "" }}
            </template>
            <template v-slot:item.created_uid="{ item }">
              {{ item.created_uid !== "none" && item.created_uid ? getAllAgentsMap[item.created_uid].name : "-" }}
            </template>
            <template v-slot:item.agent_id="{ item }">
              {{ item.agent_id !== "none" && item.agent_id ? getAllAgentsMap[item.agent_id].name : "Sin asignar" }}
            </template>
            <template v-slot:item.operations="{ item }">
              <BtnWhatsapp v-if="item.phone" :number="item.phone" />
              <BtnGmail v-if="item.email" :email="item.email" />
              <v-btn :to="{ name: 'ticket_detail', params: { id: item.id } }" target="_blank" text icon @click.stop>
                <v-icon color="primary" dark small>fas fa-external-link-alt</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-row class="pa-0">
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" class="text-center">
              <v-pagination
                v-model="page"
                :length="total_page"
                prev-icon="fas fa-angle-left"
                next-icon="fas fa-angle-right"
                total-visible="6"
                @input="loadTickets"
              ></v-pagination>
            </v-col>
            <v-col cols="12" md="3" class="d-flex align-center justify-end">
              <h5 class="font-weight-thin">
                Total de tickets: {{ total_tickets - getTickets.length + tickets.length }}
              </h5>
            </v-col>
          </v-row>
        </v-col>
        <!-- End Table -->
      </v-card>
      <v-btn
        :to="{ name: 'ticket_register' }"
        fixed
        bottom
        right
        x-large
        color="primary"
        elevation-12
        rounded
        class="hidden-sm-and-down"
      >
        <span> <v-icon size="16" class="mr-2">fa-plus</v-icon> Agregar </span>
      </v-btn>
    </div>
    <v-container v-else>
      <ShowLoading />
    </v-container>
  </v-app>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import config from "Config/config";

import { ticketsIndex } from "@/config/algolia";
import { formatFilters } from "@/helpers/format_algoliaparams";

import TicketHeader from "@/views/tickets/inc/Header.vue";
import BtnWhatsapp from "@/components/buttons/BtnWhatsapp.vue";
import BtnGmail from "@/components/buttons/BtnGmail.vue";

export default {
  name: "Tickets",
  components: {
    TicketHeader,
    BtnWhatsapp,
    BtnGmail,
  },
  data() {
    return {
      selected: [],
      tickets: null,
      states: null,
      customFilters: config.ticketFilters,
      filters_selected: {},
      query: "",
      filters: "",
      page: 1,
      total_page: 1,
      total_tickets: 0,
      headers: [],
      loadingExport: false,
      loadingTickets: false,
      showFilters: true,
    };
  },
  computed: {
    ...mapGetters([
      "getAgent",
      "getAgents",
      "getAllAgentsMap",
      "getTickets",
      "getNewTickets",
      "getActiveTicketStates",
      "getSelectedTicketId",
      "getProducts",
    ]),
  },
  watch: {
    getAgent(value) {
      if (value) this.init();
    },
    getAgents(value) {
      if (value) {
        if (this.getAgent.role === this.$roles.ADVISOR)
          this.filters_selected["agent_id"] = { value: this.getAgent.uid };
        if ([this.$roles.SUPERVISOR, this.$roles.ENTREPRENEUR].includes(this.getAgent.role))
          this.filters_selected["team_id"] = { value: this.getAgent.team_id };

        this.filters = formatFilters(this.filters_selected);
        this.loadUI();
      }
    },
    getTickets(value) {
      this.tickets = [...value];
    },
    getNewTickets(value) {
      if (value && this.query === "" && this.filters === formatFilters(this.filters_selected)) {
        for (const ticket of value) {
          let ind = this.tickets.findIndex((t) => t.id === ticket.id);
          if (ind >= 0) Vue.set(this.tickets, ind, ticket);
          else this.tickets.unshift(ticket);
        }
      }
    },
    getActiveTicketStates(value) {
      if (value) {
        this.states = {};
        for (let i = 0; i < value.length; i++) {
          this.states[value[i].id] = value[i];
        }
      }
    },
  },
  async created() {
    if (this.getAgent) this.init();
  },
  methods: {
    ...mapActions([
      "getAllAgents",
      "downloadTickets",
      "showTicketDetail",
      "setGroupTickets",
      "getAllNewTickets",
      "getAllTicketStates",
      "getAllProducts",
    ]),
    async init() {
      let headers;

      if (this.getAgent.role === this.$roles.ADVISOR) headers = config.tableTicket.filter((t) => !t.hide_advisor);

      this.headers = headers || config.tableTicket;

      if (!this.getProducts) await this.getAllProducts();
      await this.getAllTicketStates();
      await this.getAllAgents();
    },
    async loadUI() {
      let data = await ticketsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: this.filters,
      });

      this.tickets = data.hits || [];
      this.total_page = data.nbPages;
      this.total_tickets = data.nbHits;
      this.page = data.page + 1;

      this.setGroupTickets({ tickets_selected: this.tickets });
      this.getAllNewTickets({ last_ticket: this.tickets[0] });
      this.loadFilters(data.facets);
    },
    loadFilters(facets) {
      for (let filter in facets) {
        let items = [{ id: "", name: "Todos", full_name: "Todos" }];

        if (filter === "state") items.push(...this.getActiveTicketStates);
        else if (filter === "agent_id")
          items.push({ id: "none", full_name: "Sin asignar" }, ...this.loadFilterAgents(facets[filter]));
        else
          for (let field in facets[filter]) {
            items.push({ id: field, name: field });
          }

        this.customFilters[filter].items = items;
        this.customFilters[filter].value = this.filters_selected[filter] ? this.filters_selected[filter].value : "";
      }
    },
    loadFilterAgents(facet) {
      let agents = [];
      for (let f in facet) {
        if (f !== "none") agents.push(this.getAllAgentsMap[f]);
      }
      return agents;
    },
    applyFilter(filter_id, filter_value) {
      this.customFilters[filter_id].value = filter_value;
      this.page = 1;
      this.filters = formatFilters({ ...this.customFilters, ...this.filters_selected });

      this.loadTickets();
    },
    async loadTickets() {
      this.loadingTickets = true;

      let data = await ticketsIndex.search(this.query, {
        facets: Object.keys(this.customFilters),
        filters: this.filters,
        page: this.page - 1,
      });

      this.tickets = data.hits || [];
      this.total_page = data.nbPages;
      this.total_tickets = data.nbHits;
      this.page = data.page + 1;
      this.loadingTickets = false;

      this.setGroupTickets({ tickets_selected: this.tickets });
    },
  },
};
</script>

<style>
.max-w180 {
  max-width: 180px;
}

.v-avatar {
  cursor: pointer;
}

.text-decoration-none {
  text-decoration: none;
}

th[aria-label="Email: Not sorted."],
.hidden {
  display: none;
}

.v-btn {
  text-transform: none;
}

.imput-icon .v-input__icon--append i {
  font-size: 16px;
}
</style>
