import * as MutationsType from "./mutationsType";
import { teamsCollection } from "@/config/firebase";
import requestAxios from "@/helpers/request_axios";

export default {
  async getTeamById({ commit }, { id }) {
    const doc = await teamsCollection.doc(id).get();
    let team = null;

    if (!doc.empty) team = { id: doc.id, ...doc.data() };

    commit(MutationsType.SET_TEAM, team);
    return team;
  },
  async getTeamSchema({ commit }, { id, schemaIds }) {
    let schemas = [];

    for (const schemaId of schemaIds) {
      const doc = await teamsCollection.doc(id).collection("schemas").doc(schemaId).get();
      let schema = null;

      if (!doc.empty) {
        let data = doc.data();
        data = Object.entries(data);
        data.sort(function (a, b) {
          return a[1].order - b[1].order;
        });
        schema = Object.fromEntries(data);
      }

      commit(MutationsType[`SET_SCHEMA_${schemaId.toUpperCase()}`], schema);
      schemas.push(schema);
    }

    return schemas;
  },
  async getAllTeams({ commit }) {
    commit(MutationsType.SET_TEAMS, []);

    let snap = await teamsCollection.get();
    if (!snap.empty) {
      let teams = [];

      snap.forEach((doc) => {
        teams.push({ id: doc.id, ...doc.data() });
      });

      commit(MutationsType.SET_TEAMS, teams);
    }
  },
  async createTeam({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: "/awy/api/v1/team",
        method: "POST",
        data: payload.team,
      });
      commit(MutationsType.ADD_TEAM, response.data.data);
      return response.data;
    } catch (error) {
      console.error("createTeam: ", error);
      return error.response.data;
    }
  },
  async updateTeam({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/team/${payload.team_id}`,
        method: "PUT",
        data: payload.data,
      });
      commit(MutationsType.UPDATE_TEAM, response.data.data);
      return response.data;
    } catch (error) {
      console.error("updateTeam: ", error);
      throw error.response.data;
    }
  },
  // eslint-disable-next-line
  async removeTeam({ commit }, payload) {
    try {
      let response = await requestAxios({
        url: `/awy/api/v1/team/${payload.team_id}`,
        method: "DELETE",
        data: payload.data,
      });
      return response.data;
    } catch (error) {
      console.error("removeTeam: ", error);
      throw error.response.data;
    }
  },
};
